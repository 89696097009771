<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
                sticky-header="true"
                select-mode="single"
                @row-selected="onRowSelected"
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-primary">8</td>
                    <td class="td-numbering table-info">9</td>
                    <td class="td-numbering table-warning">10</td>
                    <td class="td-numbering table-success">11</td>
                    <td class="td-numbering"></td>
                </template>

                <template #head(currency)="scope">
                    <!-- <div>{{ 'Введите курс ' + selected }}</div> -->
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`${selectAll ? getDecText('del_all_recs') : getDecText('del_selected_recs')}`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(code_position)="data">
                    <div
                        class="column-width-100"
                        :class="{ 'error': data.item.checkForDuplicates }">
                        <b-form-select v-if="variantAttribute"
                                       size="sm"
                                       class="w-200px"
                                       v-model="data.item.code_position"
                                       @change="onChangeNameAfterRowSelected(data.item, data)"
                                       :options="dictPosition"
                                       value-field="code"
                        >
                        </b-form-select>
                        <div v-else>{{ dictPosition.find(el => el.code === data.value).text }}</div>
                        <template v-if="data.item.checkForDuplicates">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                <p>{{ getCommonText("exist") }}</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(code_ks)="data">
                    <b-form-select v-if="variantAttribute"
                                   size="sm"
                                   class="w-200px"
                                   v-model="data.item.code_ks"
                                   :options="dictKs"
                                   value-field="code"
                                   @change="onChangeNameAfterRowSelected(data.item, data)"
                    >
                    </b-form-select>
                    <div v-else>{{ dictKs.find(el => el.code === data.value).text }}</div>
                </template>
                <template #cell(currency)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right table-column-min-width-100"
                                  :value="data.item.currency"
                                  @change="v => data.item.currency = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'currency', data.item.currency, 2)"
                                  @focusout="onRowSelected(data.item)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(compensation)="data">
                    <template v-if="variantAttribute">
                        <b-form-input v-if="['1001', '1005', '1006'].includes(data.item.code_position)"
                                      class="text-right"
                                      :value="data.item.compensation"
                                      @change="v => data.item.compensation = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixedVldtn(data.item, 'compensation', data.item.compensation, 2)">
                        </b-form-input>
                        <div v-else class="text-right">{{ data.item.compensation }}</div>
                    </template>
                    <template v-else>
                        <div>{{ data.value }}</div>
                    </template>
                </template>
                <template #cell(rent_norm)="data">
                    <template v-if="variantAttribute">
                        <b-form-input v-if="['1001', '1005', '1006'].includes(data.item.code_position)"
                                      class="text-right"
                                      :value="data.item.rent_norm"
                                      @change="v => data.item.rent_norm = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixedVldtn(data.item, 'rent_norm', data.item.rent_norm, 2)">
                        </b-form-input>
                        <div v-else class="text-right">{{ data.item.rent_norm }}</div>
                    </template>
                    <template v-else>
                        <div>{{ data.value }}</div>
                    </template>
                </template>
                <template #cell(daily_avg)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.daily_avg"
                                  @change="v => data.item.daily_avg = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixedVldtn(data.item, 'daily_avg', data.item.daily_avg, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(rent_avg)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.rent_avg"
                                  @change="v => data.item.rent_avg = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixedVldtn(data.item, 'rent_avg', data.item.rent_avg, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(persons)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.persons"
                                  @change="v => data.item.persons = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixedVldtn(data.item, 'persons', data.item.persons, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(cost_avg)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.cost_avg"
                                  @change="v => data.item.cost_avg = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'cost_avg', data.item.cost_avg, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ $n(toNum(data.value)) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_rec')" 
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItemWithAttachedFiles(`${getCommonText('del_rec')}?`, data.item, data.index)"
                        ></i>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="10">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(toNum(total)) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="addItem"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
            />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form01_162',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, ModalFilesManagementNodecode, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-162',
                name_ru: 'Расчет расходов на служебные командировки за пределы страны',
                name_kk: 'Елден тыс жерлерге қызметтiк iссапарларға жұмсалған шығыстарды есептеу'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dictPosition: [],
            dictKs: [],
            dictCurrency: {},
            dictAllownce: {},
            dictResidence: {},
            mrp: 0,
            files: null,
            load: false,
            isLoad: false,
            openDisabled: false,
            currency: null,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
        };
    },
    async mounted() {
        await this.loadDictTripPosition();
        await this.loadDictKs();
        await this.loadDictCurrency();
        await this.loadDictAllownce();
        await this.loadDictResidence();
    },
    watch: {
        lng() {
            this.sortDictKs();
        }
    },
    methods: {
        addItem() {
            const item = { id: this.newRowStartId };
            this.newRowStartId--;
            this.itemUpdate(item);
            this.defineTotal(item);
            
            this.budgetForm.push(item);
            this.checkDoubles();
        },

        defineTotal(item) {
            Object.defineProperty(item, 'total', {
                get: () => {
                    const compensation = this.safeDecimal(item.compensation);
                    const dailyAvg = this.safeDecimal(item.daily_avg);
                    const rentNorm = this.safeDecimal(item.rent_norm);
                    const rentAvg = this.safeDecimal(item.rent_avg);
                    const persons = this.safeDecimal(item.persons);
                    const costAvg = this.safeDecimal(item.cost_avg);
                    const compMulDaily = compensation.mul(dailyAvg);
                    const rentNormMulRentAvg = rentNorm.mul(rentAvg);
                    const personsMulCoast = persons.mul(costAvg);
                    const roundedTotal = compMulDaily.add(rentNormMulRentAvg).add(personsMulCoast).div(1000).toDecimalPlaces(2).toNumber();
                    return roundedTotal;
                }
            });
        },

        onChangeNameAfterRowSelected(item, data) {
            this.onRowSelected(item);
            this.checkDoubles();
        },

        checkDoubles() {
            const firstEntryItm = {};
            this.budgetForm.forEach((itm, idx) => {
                const positionCode = itm.code_position ? itm.code_position : 'no code';   
                const ksCode = itm.code_ks ? itm.code_ks : 'no code'; 
                if (!firstEntryItm.hasOwnProperty(positionCode)) {
                    firstEntryItm[positionCode] = {};
                    firstEntryItm[positionCode][ksCode] = idx;
                    itm.checkForDuplicates = false;
                } else {
                    if (!firstEntryItm[positionCode].hasOwnProperty(ksCode)) {
                        firstEntryItm[positionCode][ksCode] = idx;
                        itm.checkForDuplicates = false;
                    } else {                        
                        itm.checkForDuplicates = true;
                        const firsDoubleIdx = firstEntryItm[positionCode][ksCode];
                        this.budgetForm[firsDoubleIdx].checkForDuplicates = true;
                    };
                };
            });
        },

        afterDeleted() {
            this.checkDoubles();
        },

        async itemUpdate(item) {
            this.$set(item, 'code_position', null);
            this.$set(item, 'code_ks', null);
            this.$set(item, 'currency', 0);
            this.$set(item, 'compensation', 0);
            this.$set(item, 'rent_norm', 0);
            this.$set(item, 'daily_avg', 0);
            this.$set(item, 'rent_avg', 0);
            this.$set(item, 'persons', 0);
            this.$set(item, 'cost_avg', 0);
            this.$set(item, 'itemToDelete', false);

            // этот атрибут чтобы найти схожий запись по наименованию
            this.$set(item, 'checkForDuplicates', false);

            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
            }
            this.load = false;
        },

        getFiles(data) {
            this.files = data;
        },

        getItem(field, code, list, tripPosition = null) {
            const curList = list[code];
            if (!curList || curList.length === 0) return null;

            const item = curList.filter(function (row) {
                const isTripPosEqual = !tripPosition || row['code_trip_position'] === tripPosition;
                if (row[field] === code && isTripPosEqual) {
                    return row;
                }
            });
            return (item !== undefined ? item[0] : null);
        }, // возвращает объект по коду с заданного списка

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            const that = this;

            this.load = true;
            await this.budgetForm.splice(0);
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                values = await response.json();

                // в этот массив записывается все наименование записей, потом по этому массиву идет проверка
                // если в него есть массив то атрибут checkForDuplicates равен true
                const listCheckDuplicates = [];

                await values.forEach(val => {
                    const item = val;
                    item['checkForDuplicates'] = !!listCheckDuplicates.find(el => el.code_position === item.code_position && el.code_ks === item.code_ks)

                    item['files'] = val.files;
                    item['row_files'] = val.row_files;
                    item['itemToDelete'] = false;
                    listCheckDuplicates.push({
                        'code_position': item.code_position,
                        'code_ks': item.code_ks
                    });
                    this.defineTotal(item);

                    this.budgetForm.push(item);
                });
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas`, error.toString());
            }
            this.load = false;
        },

        async loadDictAllownce() {
            try {
                const response = await fetch('/api-py/dictionary/allownce/');
                const result = await response.json();
                this.dictAllownce = this.arrayToDict(result, 'code_currency');
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDictAllownce`, error.toString());
            }
        },

        async loadDictCurrency() {
            try {
                const response = await fetch('/api-py/dictionary/currency/');
                const result = await response.json();
                this.dictCurrency = this.arrayToDict(result, 'code_ks');
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDictCurrency`, error.toString());
            }
        },

        async loadDictKs() {
            try {
                const response = await fetch('/api-py/dictionary/ks/');
                const items = await response.json();
                this.dictKs.splice(0);
                for (const itm of items) {
                    if (itm.par_id === null) itm.disabled = true;
                    Object.defineProperty(itm, 'text', {
                        get: () => {
                            if (this.lng === 'kk') return itm.name_kz;
                            return itm.name_ru;
                        }
                    });
                    this.dictKs.push(itm);
                }
                this.sortDictKs();
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDictKs`, error.toString());
            }
        },

        sortDictKs() {
            this.dictKs.sort((a, b) => {
                if (!a.text || !b.text) return 0;
                if (a.text.toLowerCase() < b.text.toLowerCase()) {
                    return -1;
                }
                if (a.text.toLowerCase() > b.text.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        },

        async loadDictResidence() {
            try {
                const response = await fetch('/api-py/dictionary/residence/');
                const result = await response.json();
                this.dictResidence = this.arrayToDict(result, 'code_ks');
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDictResidence`, error.toString());
            }
        },

        arrayToDict(arr, fieldName) {
            if (arr.length === 0) return null;
            const dict = {}
            arr.forEach(elem => {
                const keyField = elem[fieldName];
                if (dict[keyField]) {
                    dict[keyField].push(elem);
                } else {
                    this.$set(dict, keyField, [elem]);
                }
            })
            return dict;
        },

        async loadDictTripPosition() {
            try {
                const response = await fetch('/api-py/dictionary/trip_position/');
                const items = await response.json();
                this.dictPosition.splice(0);
                for (const itm of items) {
                    Object.defineProperty(itm, 'text', {
                        get: () => {
                            if (this.lng) return itm[`name_${this.lng}`];
                            return itm.name_ru;
                        }
                    });
                    this.dictPosition.push(itm);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDictTripPosition`, error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        toNum(value, fix = 1) {
            return this.safeDecimal(value).toDecimalPlaces(fix).toNumber();
        },

        onRowSelected(item) {
            const curCurr = this.getItem('code_ks', item.code_ks, this.dictCurrency);
            this.$set(item, 'code_currency', curCurr?.code)

            const allownce = this.getItem('code_currency', item.code_currency, this.dictAllownce);
            this.$set(item, 'allownce', allownce === null || allownce === undefined ? 0 : allownce?.daily_allownce);

            const currency = this.safeDecimal(item.currency);
            const allownceDec = this.safeDecimal(item.allownce);
            this.$set(item, 'compensation', currency.mul(allownceDec).toNumber());
            
            const residence = this.getItem('code_ks', item.code_ks, this.dictResidence, item.code_position);
            this.$set(item, 'residence', residence === null || residence === undefined ? 0 : residence?.rate_residence);

            const residenceDec = this.safeDecimal(item.residence);
            this.$set(item, 'rent_norm', currency.mul(residenceDec).toNumber());

            this.currency = curCurr;
        },

        prepareForSave() {
            const values = [];
            let error = false;
            for (const row of this.budgetForm) {
                if (!((row.code_position === null) || (row.code_ks === null))) {
                    const item = Object.assign({}, this.header);
                    this.$set(item, 'id', row.id);
                    this.$set(item, 'code_position', row.code_position);
                    this.$set(item, 'code_ks', row.code_ks);
                    this.$set(item, 'currency', row.currency);
                    this.$set(item, 'compensation', row.compensation);
                    this.$set(item, 'rent_norm', parseFloat(row.rent_norm));
                    this.$set(item, 'daily_avg', parseInt(row.daily_avg));
                    this.$set(item, 'rent_avg', parseInt(row.rent_avg));
                    this.$set(item, 'persons', parseInt(row.persons));
                    this.$set(item, 'cost_avg', parseFloat(row.cost_avg));
                    this.$set(item, 'row_files', row.row_files);
                    this.$set(item, 'total', row.total);
                    values.push(item);
                } else {
                    error = true;
                }
            }
            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (this.budgetForm.find(item => item.checkForDuplicates === true)) {
                this.makeToast('danger', this.getErrText('warning'), `${this.getErrText('warning')}. ${this.getErrText('not_saved')}!`);
            }
            else {
                if (values.length && this.variantAttribute) {
                    this.save(values, error);
                }
                else {
                    this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
                }
            }
        }, // подготовка к сохранению

        async save(values, error) {
            this.isLoad = true;
            try {
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-brform' + this.form.code + '/'
                const totalToSave = this.totalCalculation(values, 'total')
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных
    },
    computed: {
        total() {
            return this.totalCalculation(this.budgetForm, 'total');
        },

        getFormText() {
            return this.setFormText('form_01_16x.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'code_position',
                        label: this.getFormText('pos_name')
                    },
                    {
                        key: 'code_ks',
                        label: this.getFormText('country_selection')
                    },
                    {
                        key: 'currency',
                        label: this.getFormText('enter_rate', {currency: this.selected})
                    },
                    {
                        key: 'compensation',
                        label: this.getFormText('compensation_norm')
                    },
                    {
                        key: 'rent_norm',
                        label: this.getFormText('expense_norm')
                    },
                    {
                        key: 'daily_avg',
                        label: this.getFormText('avg_ann_daily_exp')
                    },
                    {
                        key: 'rent_avg',
                        label: this.getFormText('avg_ann_rent_exp')
                    },
                    {
                        key: 'persons',
                        label: this.getFormText('avg_ann_business_trip')
                    },
                    {
                        key: 'cost_avg',
                        label: this.getFormText('avg_trip_cost')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('expense_sum')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },

        selected() {
            return this.currency === null || this.currency === undefined ? this.getFormText('usd_eur') : this.currency[`name_${this.lng}`];
        },
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
    .table-column-min-width-100 {
        min-width: 80px;
    }
</style>